.fullscreenPage {


  #pageFullScreen {
    overflow-y: auto;
  }

  .page__header { display: none; }

  .menu-nav--secondary {
    display: none;
  }

  .menu-nav--viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

}