.app-header {
  position: relative;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: $heightMenuMobile;
  padding: 15px 15px 0 15px;

  border-bottom: 3px solid #a04449;

  //@include flexbox();
  //@include alignItems();

  overflow: hidden;

  background-color: $bkg_header;

  @media (min-width: $screen-md) {
    display: none;
  }

  .menu-toggle-area {
    display: inline-block;
    margin: 0 10px 0 0;

    .mobile-menu-toggle {
      z-index: 20;

      background-color: transparent;
      border: 0;
      outline: none;

      .icon-menu {
        display: inline-block;
        width: 22px;
        height: 22px;

        fill: white;
      }
    }
  }

  .brand {
    position: relative;
    top: -3px;
    display: inline-block;

    font-family: $family_heading;
    font-size: 20px;
    color: #fff;
  }
}
//
//.header-middle-area {
//  @include colFlexbox(1);
//}

//@media (max-width: 650px) {
//  .site-header {
//    padding: 5px
//  }
//}

//.mobile-menu-toggle {
//  z-index: 20;
//
//  .icon-menu {
//    display: inline-block;
//    width: 22px;
//    height: 22px;
//
//    fill: white;
//  }
//}

//.menu-toggle-area {
//  display: none;
//  padding: 0 10px
//}


//@media (max-width: 1085px) {
//
//  .menu-toggle-area {
//    display: inline-block;
//    margin-right: 10px;
//    margin-left: 1rem
//  }
//}
//
//@media (max-width: 735px) {
//
//  .menu-toggle-area {
//    display: block;
//    padding: 0;
//    margin-left: 0;
//    margin-right: 10px
//  }
//}
//
//@media (max-width: 650px) {
//
//  .site-header {
//    @include flexWrap();
//  }
//}