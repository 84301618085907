input[type="checkbox"], input[type="radio"] {
  padding: 0
}

input[type="search"] {
  -webkit-appearance: textfield
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0
}

textarea {
  overflow: auto;
  vertical-align: top
}

.form {
  //max-width: 600px;
  margin: 20px 0 0 0;

  border-top:1px solid #eee;

  @media screen and (min-width: $screen-md) {
    margin: 40px 0 0 0;

    border-top:0;
  }

  &__title {
    margin: 0 0 20px 0;

    color: #3f3e44;
  }
  &__text {
    margin: 0 0 20px 0;
  }

  &__input {
    margin: 0 0 20px 0;
    display: inline-block;
    padding: 6px 12px;
    width: 100%;
    //max-width: 500px;

    border:1px solid rgb(238,238,238);
  }

  &__textarea {
    margin: 0 0 20px 0;
    display: inline-block;
    padding: 6px 12px;
    width: 100%;
    //max-width: 800px;
    height: 170px;

    border: 1px solid rgb(238,238,238);

  }

  &__label {
    display: block;
    margin: 0 0 5px 0;
  }

  &__btn {
    display: inline-block;
    width: auto;
    margin: 0;
    padding: 12px 20px;

    color: #fff;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;

    border-radius: 2px;
    border: 1px solid transparent;

    &:hover {
      outline: 0;
      text-decoration: none;
    }

    &--send {
      color: #fff;
      background-color: $color_red;

      &:hover {
        background-color: #954044;
        border-color: #753235;
      }

      &[disabled] {
        opacity: .6;
      }
    }
  }

  &__result {
    display: block;
    margin: 30px 0 0 0;

  }

  &__group-left {


    @media screen and (min-width: $screen-md) {
      float: left;
      width: 400px;
    }
  }
  &__group-right {

    @media screen and (min-width: $screen-md) {
      float: right;
      width: calc(100% - 430px)
    }
  }
  &__group-btn {
    @media screen and (min-width: $screen-md) {
      clear: both;
      position: relative;
      top: -65px;
    }

  }
}