.page {
  position: relative;
  overflow: hidden;

  background: #fff;

  //@media (max-width: $screen-mx-md) {
  //  @include colFlexbox(1);
  //  -ms-grid-column: 1
  //}


  &__header {
    position: relative;
    padding: 15px 20px 15px;

    background-color: $bk_header_title;
    color: white;

    @media (min-width: $screen-md) {
      //padding: 15px 20px 0;
    }

    .h1 {
      display: inline-block;
      margin: 0;
      padding-bottom: 10px;

      color: #999;
      font-size: 14px;

      border-bottom: 1px solid #eee;

      @media (min-width: $screen-md) {
        font-size: 20px;
      }
    }
    .h2 {
      margin: 10px 0 0;

      @media (min-width: $screen-md) {

      }
    }
    p {
      margin: 5px 0 0 0
    }

    @media (min-width: $screen-md) {
      //height: 184px;
      height: 139px;

      //border-bottom: 1px solid #eee;

      .h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .h2 {
        white-space: nowrap;
        //overflow: hidden;
        text-overflow: ellipsis;
      }
    }

  }

  &__wrapper {
    @include flexbox();
    @include flexDirection('column');
    @include flexNoWrap();
  }

  &__content {
    width: 100%;
    min-height: 100vh;
  }

  &__sidebar {
    display: none;
    width: 300px;
    min-height: 100%;

    background: url("../images/sidebar-viewer.png") repeat;

    &.open {
      display: block;
      position: fixed;
      z-index: 10;
      top: $heightMenuMobile;
      right: 0;
    }

    @media (min-width: $screen-sm) {
      width: 350px;
    }

    @media (min-width: $screen-sm) {
     top: 0;
    }

    @media (min-width: $screen-mx-macpro) {
      width: 400px;

      &.open {
        position: relative;
        top: 0;
      }
    }

    @media (min-width: $screen-mx-full) {
      width: 500px;
    }

  }

  &__btn-contact {
    display: none;

    @media (min-width: $screen-md) {

      opacity: .5;
      position: absolute;
      display: inline-block;
      width: auto;
      right: 20px;
      top: 20px;

      font-family: $family_heading;
      font-size: .66rem;
      color: #fff;
      text-align: center;

      svg {
        fill: #fff;
      }

      span {
        margin-top: 5px;
        display: block;
        text-transform: uppercase;
      }

      @include hover(true) {
        opacity: 1;
        span {
          color: #fff;
        }
      }

    }

  }

  &__btn-home {
    display: none;

    @media (min-width: $screen-md) {
      opacity: .5;
      position: absolute;
      display: inline-block;
      width: auto;
      right: 90px;
      top: 20px;

      font-family: $family_heading;
      font-size: .66rem;
      color: #fff;
      text-align: center;

      transition: opacity 0.5s linear;

      svg {
        fill: #fff;
      }

      span {
        margin-top: 5px;
        display: block;
        text-transform: uppercase;
      }

      @include hover(true) {
        opacity: 1;
        span {
          color: #fff;
        }
      }
    }
  }

  &__filter {
    position:relative;
    margin: 0 0 20px;

    text-align: center;

    padding-bottom: 15px;
    border-bottom: 1px solid #d9d9d9;

    transition: .4s all;

    @media screen and (min-width: $screen-sm) {
      margin: 0 20px 20px;
    }

    &--novisible {
      top: -1000px;
    }

    &--left {
      text-align: left;
      margin-left: 0;
    }


  }

  &__filter-option {
    position:relative;
    top: 0px;
    display: inline-block;
    margin: 0 15px;
    padding: 15px;
    max-width: 100%;

    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;

    background-color: #d9d9d9;

    transition: .4s all;

    @media screen and (min-width: $screen-sm) {
      font-size: 9px;
    }

    @include hover() {
      background-color: #03a9f4;
      color: #fff;
    }

    &--select {
      margin: 0 0 10px 0;
      max-width: 100%;
      width: 100%;
      height: auto;
      background-color: #fff;
      color: #333;
      font-family: $family_heading;

      @include hover() {
        background-color: #fff;
        color: #333;
      }

      @media screen and (min-width: $screen-sm) {
        margin: 0;
        width: auto;
      }

      @media screen and (min-width: $screen-md) {
        height: 44px;

      }

      option {
        color: #333;
      }
    }
  }

  &__nodata {

    &--filtered {
      //margin-left: 20px;
    }
  }

  &__grid {
    @include flexbox();
    @include flexWrap();
    @include flexDirection('column');
  }

  &__wrap {
    font-family: $family_heading;

    @include flexbox();
    @include flexDirection('column');
    @include flexNoWrap();


    &--books {

    }

    &--contact {
      position: relative;
      padding: 20px;

      @media screen and (min-width: $screen-md) {
        padding: 40px;
      }
    }

    &--books,
    &--chapters,
    &--videos,
    &--images,
    &--documents,
    &--links,
    &--notes {
      position: relative;
      padding: 20px;

      .module {
        opacity: 1;
        transition: .8s opacity;

        @include flexbox();

        @include hover() {
          opacity: 0.3;
        }
      }

      @include hover() {
        .module {
          opacity: 0.8;

          @include hover() {
            opacity: 1;
          }
        }
      }
    }

    &--books {
      background-color: #eee;

      .module {

        //max-width: 100%;
        //@include colFlexboxAdvance('1 1 100%');
        //
        //
        //@media screen and (min-width: $screen-sm) {
        //  max-width: 50%;
        //  @include colFlexboxAdvance('1 1 50%');
        //}
        //
        //@media screen and (min-width: $screen-md) {
        //  max-width: 33.333%;
        //  @include colFlexboxAdvance('1 1 33.333%');
        //}
        //
        //@media screen and (min-width: $screen-mx-macpro) {
        //  max-width: 33.333%;
        //  @include colFlexboxAdvance('1 1 33.333%');
        //}
        //
        //
        //@media screen and (min-width: $screen-full) {
        //  max-width: 25%;
        //  @include colFlexboxAdvance('1 1 25%');
        //}

        .module__content {

        }
      }
    }

    &--chapters {
      .module {

        max-width: 100%;
        @include colFlexboxAdvance('1 1 100%');

        @media screen and (min-width: $screen-sm) {
          max-width: 50%;
          @include colFlexboxAdvance('1 1 50%');
        }

        @media screen and (min-width: $screen-mx-macpro) {
          max-width: 33.33%;
          @include colFlexboxAdvance('1 1 33.33%');
        }

        //
        //@media screen and (min-width: $screen-full) {
        //  max-width: 25%;
        //  @include colFlexboxAdvance('1 1 25%');
        //}
      }
    }

    &--videos,
    &--images {
      background-color: #eee;

      .module {

        max-width: 100%;
        @include colFlexboxAdvance('1 1 100%');

        @media screen and (min-width: $screen-sm) {
          max-width: 50%;
          @include colFlexboxAdvance('1 1 50%');
        }

        @media screen and (min-width: $screen-macpro) {
          max-width: 33.33%;
          @include colFlexboxAdvance('1 1 33.33%');
        }


        //@media screen and (min-width: $screen-full) {
        //  max-width: 33.33%;
        //  @include colFlexboxAdvance('1 1 33.33%');
        //}
      }
    }

    &--documents,
    &--links {
      .module {

        max-width: 100%;
        @include colFlexboxAdvance('1 1 100%');

        @media screen and (min-width: $screen-sm) {
          max-width: 50%;
          @include colFlexboxAdvance('1 1 50%');
        }

        @media screen and (min-width: $screen-macpro) {
          max-width: 33.33%;
          @include colFlexboxAdvance('1 1 33.33%');
        }

        //
        //@media screen and (min-width: $screen-full) {
        //  max-width: 25%;
        //  @include colFlexboxAdvance('1 1 25%');
        //}
      }
    }


    &--notes {
      .module {
        max-width: 100%;
        @include colFlexboxAdvance('1 1 100%');

        @media screen and (min-width: $screen-sm) {
          max-width: 50%;
          @include colFlexboxAdvance('1 1 50%');
        }

        @media screen and (min-width: $screen-macpro) {
          max-width: 33.33%;
          @include colFlexboxAdvance('1 1 33.33%');
        }
      }
    }
  }
}

.popup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(73,70,77,.8);

  @include flexbox();
  @include flexDirection(column);
  @include alignItems();
  @include justifyContent();


  &__close {
    position: absolute;
    right: 2rem;
    top: 2rem;

    fill: #fff;

  }

  &__element {
    //@include colFlexbox(1);
  }

  &--privacity {
    z-index: 100;
    padding: 80px 40px 40px;
    background-color: #fff;

    font-family: $family_heading;

    justify-content: flex-start;

    .popup__close {
      fill: #3f3e44;

    }

    .popup__text-separate {
      margin-top: 40px;
    }
  }
}