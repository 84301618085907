.rotate0 {-webkit-transform: rotate(0deg); transform: rotate(0deg); }
.rotate90 {-webkit-transform: rotate(90deg); transform: rotate(90deg); }
.rotate180 {-webkit-transform: rotate(180deg); transform: rotate(180deg); }
.rotate270 {-webkit-transform: rotate(270deg); transform: rotate(270deg); }


.viewer-content {
  width: 100%;
}

.viewer {
  position: relative;

  @media print {
    display: none;
  }

  &__controls {
    padding: 10px 20px;
    margin: 0;

    color: white;
    background: #fcfcfc;

    &--fixed {
      position: fixed;
      width: calc(100% - 428px);
      top: 0;
      z-index: 999999;

      border-top: 8px solid $color-red;
    }
  }

  &__loading {
    position: absolute;
    top: 300px;
    left: 50%;
    z-index: 10;
    transform: translateX(50%) translateY(0);
  }

  &__option {
    margin: 0 3px;
    width: auto;
    //height: 40px;
    color: #6f6d76;

    border: 0;
    background: none;

    @include hover() {
      outline: none;
    }

    &--menu {
      padding: 0;
      margin: 0;
      border: 0;

      height: 25px;

      .has-sub .ico-hover { display: none; position: absolute;}
      .has-sub:hover .ico-hover { display: inline-block;}
      .ul-higher { z-index: 1000; }

      .list-text-size { width: 350px !important;}
      .list-text-notes { width: 500px !important;}

      .item-list-note { border-bottom: 1px solid #aaa; text-align: center;}

      ul, li {
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
      }

      ul {
        position: relative;
        z-index: 597;
        float: left;
      }

      ul li {
        float: left;
        min-height: 1px;
        line-height: 1em;
        vertical-align: middle;
      }

      ul li.hover,
      ul li:hover {
        position: relative;
        z-index: 599;
        cursor: default;
      }

      ul ul {
        visibility: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 598;
        width: 100%;
      }

      ul ul li {
        float: none;
      }

      ul li:hover > ul {
        visibility: visible;
      }

      ul ul {
        top: 0;
        left: 100%;
        min-width: 150px;
      }

      ul ul.first-ul {
        top: 43px;
        left: -63px;
      }

      ul li {
        float: none;
      }

      span, a {
        display: inline-block;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
        text-decoration: none;
      }

      li { background: #dddddd; }
      li:hover { background: #f6f6f6; }

      a {
        color: #666666;
        line-height: 160%;
        padding: 11px 28px 11px 28px;
        width: 300px;
      }
      a.resource-list {
        width: 144px;
      }
      a.link-icon {
        width: auto;
        padding-left: 6px;
        padding-right: 6px;
        background-color: #fcfcfc;
      }

      ul ul li {
        background: #f6f6f6;
      }

      ul ul li:hover {
        background: #dddddd;
      }

      ul ul li:hover a {
        color: #666666;
      }

      ul ul li ul li {
        background: #dddddd;
      }

      ul ul li ul li:hover {
        background: #b7b7b7;
      }

      .has-sub {
        position: relative;
      }

      .has-sub:after, .has-sub > ul > .has-sub:hover:after {
        content: '';
        display: block;
        width: 10px;
        height: 9px;
        position: absolute;
        right: 5px;
        top: 50%;
        margin-top: -5px;
        background-image: url(right.png);
      }

      .has-sub > ul > .has-sub:after, .opt-menu .has-sub:hover:after {
        background-image: url(right.png);
      }
    }

  }

  &__option:focus {
    outline: 0;
  }

  &__layer-page {
    display: inline-block;
    margin-left: 30px;

    span {
      font-size: 12px;
      color: #6f6d76;
    }
  }

  &__ipt-page {
    width: 30px;
    font-size: 12px;
    text-align: center;
  }

  &__content-form {
    position: absolute;
    //top: -1000px;

    z-index: 10;
    width: 100%;
    padding: 10px 20px;
    margin: 0;

    color: $txt_color_menu_viewer;
    background: $bkg_menu_viewer;

    &.close {
      top: -1000px;
    }
    &.open {
      top: 55px;
    }
  }

  &__textarea {
    width: 100%;
    height: 300px;
  }

  &__select {
    display: inline-block;
    width: 200px;
    margin: 10px 0;
    padding: 10px 17px;
    height: 25px;

    color: #333;
    font-size: 8px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;

    background-color: #fff;
  }

  &__result {
    display: inline-block;
    margin-left: 15px;

    color: #3f3e44;
    font-weight: bold;
  }

  &__btn {
    display: inline-block;
    width: auto;
    margin: 10px 0;
    padding: 10px 17px;

    color: #fff;
    font-size: 8px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;

    border-radius: 2px;
    border: 1px solid transparent;

    &:hover {
      outline: 0;
      text-decoration: none;
    }


    &--save {
      color: #fff;
      background-color: $color_red;

      &:hover {
        background-color: #954044;
        border-color: #753235;
      }

      &[disabled] {
        opacity: .6;
      }
    }
  }


  .viewer__option--menu:after,
  .viewer__option--menu ul:after {
    content: '';
    display: block;
    clear: both;
  }
}