h1, h2, h3, h4, h5, h6 {
  font-weight: 900;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: #222;
  border: 0
}

h1 a:hover, h1 a:focus,
h2 a:hover, h2 a:focus,
h3 a:hover, h3 a:focus,
h4 a:hover, h4 a:focus,
h5 a:hover, h5 a:focus,
h6 a:hover, h6 a:focus {
  color: $color_heading;
}


h1, .h1 {
  font-family: $family_heading;
  font-size: 24px;
  font-weight: 700;

  @media (min-width: $screen-md) {
    font-size: 30px;
  }
}
h2, .h2 {
  font-family: $family_heading;
  font-size: 20px;
  font-weight: 700;

  @media (min-width: $screen-md) {
    font-size: 26px;
  }
}

h3, .h3 {
  font-family: $family_heading;
  font-size: 15px;
  font-weight: 700;

  @media (min-width: $screen-md) {
    font-size: 17px;
  }
}
h4, .h4 {
  font-family: $family_heading;
  font-weight: 400;
  font-size: 13px;
  margin: 0 0 8px 0;

  @media (min-width: $screen-md) {
    font-size: 12px;
  }
}
h5, .h5 {
  font-size: 12px;
  font-weight: 800;
  margin: 0 0 5px 0;

  @media (min-width: $screen-md) {
    font-size: 10px;
  }
}

h6 {
  font-size: 12px;
  font-weight: 400;

  @media (min-width: $screen-md) {
    font-size: 10px;
  }
}

h4, .h4, h5, .h5, h6, .h6 {
  line-height: 1.05
}

b, strong {
  font-weight: bold
}

blockquote {
  margin: 0 0 10px 0;
  padding: 12px;
  background: linear-gradient(to right, #eee, white);
  border-left: 8px solid #ccc
}

blockquote p:last-child {
  margin: 0
}

a {
  color: #03a9f4
}

a:hover, a:focus {
  color: #333
}

.is-dark a:hover, .is-dark a:focus {
  color: #92dcfe
}

p, pre, figure {
}