@mixin hover ($only_hover: false) {
  @if $only_hover==true {
    &:hover {
      @content;
    }
  }
  @else {
    &:hover, &:focus, &:active {
      @content;
    }
  }
}


@mixin flexbox () {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin colFlexbox($col) {
  -webkit-box-flex: $col;
  -webkit-flex: $col;
  -moz-box-flex: $col;
  -ms-flex: $col;
  flex: $col
}

@mixin colFlexboxAdvance($colAdvance) {
  -webkit-box-flex: 0;
  -webkit-flex: $colAdvance;
  -moz-box-flex: $colAdvance;
  -ms-flex: $colAdvance;
  flex: $colAdvance
}

@mixin setFlexWrap ($type) {
  -webkit-flex-wrap: $type;
  -ms-flex-wrap: $type;
  flex-wrap: $type
}

@mixin flexWrap ($type: wrap) {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

@mixin flexNoWrap () {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

@mixin flexReverse () {
  -webkit-flex-wrap: wrap-reverse;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse
}

@mixin flexDirection ($direction) {
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction
}

@mixin alignItems () {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin justifyContent () {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

