.layer {

  &--grey {
    padding: 10px 20px;
    margin: 0;

    color: white;
    background: #7f7f7f;
  }

  &--header {
    position: relative;
    padding: 20px;

    background-color: $bk_header_title;
    color: white;

    p {
      margin: 5px 0 0 0
    }
  }

  &--with-fixed {
    padding-top: 100px;
  }
}