.wrapper {
  position: relative;
  padding: $heightMenuMobile 0 0 0;
  width: 100%;
  min-height: 100vh;

  @include flexbox();
  @include flexDirection('column');
  @include flexNoWrap();

  @media (min-width: $screen-md) {
    padding: 0;
  }


  &__menu {
    display: none;
    width: 100%;
    background-color: #57565C;

    &.open {
      display: block;
      position: fixed;
      z-index: 10;
      top: $heightMenuMobile;
    }

    @media (min-width: $screen-md) {
      display: block;
      width: 200px;
      min-height: 300px;

      &.open {
        display: block;
        position: relative;
        top: 0;
      }
    }

  }

  &__section {
    //width: 50%;
    width: 100%;
    min-height: 400px;
  }

}