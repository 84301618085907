.module {
  position: relative;
  padding: 0;
  margin: 0 0 20px 0;
  @include flexbox();

  @media screen and (min-width: $screen-sm) {
    padding: 20px;
    margin: 0;
  }

  &__header {
    position: relative;
    top: 0;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #eee;
  }

  &__advice {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 20px;
  }
  .module__advice-content {
    position: absolute;
    top: 20px;
    left: 20px;
    bottom: 20px;
    right: 20px;
    padding: 20px;

    text-align: center;

    background: rgba(255,255,255,.95);
    border: 1px solid #eee;

    .text-advice {
      margin-bottom: 20px;

      font-weight: 600;
    }
  }
  .module__action {
    display: inline-block;


  }

  &__page {
    position: absolute;
    right: 0;
    font-size: 8px;
  }

  &__date {
    font-size: 8px;
  }

  &__chapter {
    min-height: 42px;
    margin: 5px 0 0 0;
    font-size: 10px;
    font-weight: 700;
  }
  &__title {
    display: block;
    margin: 0;
  }
  &__title-small {
    display: block;
    font-size: 8px;
    margin: 0;
  }


  &__footer {
    position: relative;
    top: 0;
    margin: auto 0 0 0;
    padding: 0;
  }

  &__icon {
    position: absolute;
    width: 25px;
    height: auto;
    right: 40px;
    top: -15px;
  }

  &__action {
    position: relative;
    padding: 6px 12px;
    color: #a04449;
    font-size: 14px;

    background-color: #efefef;
    border-radius: 2px;

    transition: 0.3s;

    @include hover(true) {
      color: #fff;
      background-color: #a04449;
    }

    &--delete {
      opacity: .7;

      color: #fff;
      background-color: #a04449;

      @include hover(true) {
        opacity: 1;
      }
    }

    &--cancel {
      opacity: .7;

      color: #fff;
      background-color: #57565C;

      @include hover(true) {
        opacity: 1;
        background-color: #3f3e44;
      }
    }
  }

  &__image {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  &__video {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    height: auto;
    min-height: 270px;

    iframe {
      width: 100%;
    }
  }

  &__link {
    display: block;
    margin: 10px 0 0 0;
    padding: 5px;

    font-size: 8px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    text-transform: uppercase;

    background-color: $color_red;
  }

  &__document {
    display: block;
    margin: 10px 0 0 0;
    padding: 5px;

    font-size: 8px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    text-transform: uppercase;

    background-color: $color_red;
  }

  &__content {
    //position: relative;
    font-family: $family_heading;
    padding: 20px;
    max-width: 100%;


    @media screen and (min-width: $screen-sm) {
      width: 324px;
    }

    @media screen and (min-width: $screen-md) {
      width: 368px;
    }


    @include flexbox();
    @include flexDirection(column);

    background: rgba(255, 152, 0, 0.2);

    &[type="anotacion"] {
      color: #262626;
      background-color: #f5f5f5;

      .module__header {
        border-bottom: 1px solid #666;
      }
    }

    &[type="pregunta"] {
      color: #262626;
      background-color: #d9d9d9;
    }

    &[type="recordatorio"] {
      color: #fff;
      background-color: #262626;
    }

    &[type="duda"] {
      color: #262626;
      background-color: #fff;

      .module__header {
        border-bottom: 1px solid #666;
      }
    }

    &[type="importante"] {
      color: #fff;
      background-color: #82b440;
    }

    &[type="link"] {
      color: #fff;
      background-color: #8C2C25;
    }

    &[type="document"] {
      color: #fff;
      background-color: #d9d9d9;
      color: #262626;
    }

    &[type="image"] {
      color: #333;
      background-color: #fff;
      border: 1px solid #eee;
    }

    &[type="video"] {
      color: #333;
      background-color: #fff;
      border: 1px solid #eee;
    }

    &[type="chapter"] {
      color: #333;
      background-color: #fff;
      border: 1px solid #eee;
    }

    &[type="book"] {
      color: #333;
      background-color: #fff;
    }
  }

  &--video,
  &--image {
    .module__content {
      padding: 20px 0 0;
    }

    .module__header {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 0;

      border-bottom: 0;
    }
    .module__footer {
      padding: 10px 20px 20px;
    }
  }

  &--chapter {
    .module__content {
      padding: 20px 0 0;
    }

    .module__header {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 0;

      border-bottom: 0;
    }
    .module__footer {
      margin-top: 10px;
      padding: 10px 20px 20px;

      * {
        margin-top: 0;
      }
    }
  }

  &--note {

    .module__content {
      padding-bottom: 40px;
    }

    .module__footer {
      position: absolute;
      top: auto;
      bottom: 35px;
      left: 0;
      right: 40px;

      text-align: right;

      //border-top: 1px solid #eee;
    }

  }

  &--book {
    .module__content {
      padding: 20px 0 0;

      border-radius: 0;

      @media screen and (min-width: $screen-sm) {
        width: 320px;
      }

      @media screen and (min-width: $screen-md) {
        width: 232px;
      }

      @media screen and (min-width: $screen-macpro) {
        width: 350px;
      }
    }


    .module__image {
      margin-top: auto;
      width: auto;
    }
    .module__footer {
      padding: 10px 20px 20px;

      background-color: #55545a;
      color: #fff;

      @media screen and (min-width: $screen-sm) {
        min-height: 85px;
      }
    }
  }



}