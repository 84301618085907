//************/
//*  Init resets  */
//************/
:root {
  --spacing: 1rem
}
*, *::after, *::before {
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

a {
  text-decoration: none
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block
}

audio, canvas, video {
  display: inline-block
}

audio:not([controls]) {
  display: none;
  height: 0
}

[hidden] {
  display: none
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  margin: 0;
  padding: 0
}

body {
  margin: 0
}

dl, menu, ol, ul {
  margin: 0;
  list-style: none
}

dd {
  margin: 0
}

menu, ol, ul {
  padding: 0
}

nav ul, nav ol {
  list-style: none;
  list-style-image: none
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic
}

svg:not(:root) {
  overflow: hidden
}

figure {
  margin: 0
}

form {
  margin: 0
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle
}

button, input {
  line-height: normal
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer
}

button[disabled], input[disabled] {
  cursor: default
}

.clear {
  clear: both;
}

/*.hidden {
  display: none;
}*/

//************/
//*  General  */
//************/
:root {
  --spacing: 1rem
}

html {
  width: 100%;
  overflow-x: hidden;

  font-family: $main_family;
  font-weight: 400;
  line-height: 1.5;

  font-size: $base_size;
}


//@media screen and (min-width: 500px) {
@media screen and (min-width: $screen-mx-mobile-min) {
  html {
    font-size: calc($base_size + 8 * ((100vw - 500px) / 1500))
  }
}

//@media screen and (min-width: 2000px) {
@media screen and (min-width: $screen-full) {
  html {
    font-size: $base_size_big;
  }
}


.visible-xs {
  @media (min-width: $screen-sm) {
    display: none !important;
  }
}

.visible-xs-sm {
  @media (min-width: $screen-md) {
    display: none !important;
  }
}

.hidden-xs {
  @media (max-width: $screen-mx-sm) {
    display: none !important;
  }
}

.hidden-md {
  @media (min-width: $screen-md) {
    display: none !important;
  }
}


//************/
//*  Estructura  */
//************/

#csrtok {display: none; }

.all-site-wrap {
  padding-top: $heightMenuMobile;

  @include flexbox();
  display: -ms-grid;
  -ms-grid-columns: 100px 1fr calc(150px + 20px);

  min-height: 100vh;

  background-color: #3c3c3c;

  @media (min-width: $screen-md) {
    padding-top: 0;
    border-top: 8px solid $color_red;
  }

  .menu-nav--main {
    @include colFlexboxAdvance('0 0 100px');
    -ms-grid-column: 1
  }

  .page {
    @include colFlexbox(1);
    -ms-grid-column: 2;
  }

  .sidebar {
    @include colFlexboxAdvance('0 0 0');
    -ms-grid-column: 3;

    &--viewer {
      @include colFlexboxAdvance('0 0 calc(300px + 20px)');
    }

    &--contact {
      @include colFlexboxAdvance('0 0 calc(300px + 20px)');
    }
  }

  &--viewer {
    -ms-grid-columns: 100px 1fr calc(350px + 20px);
  }

  &--contact {
    -ms-grid-columns: 100px 1fr calc(350px + 20px);
  }

  @media screen and (min-width: $screen-md) {
    //-ms-grid-columns: 100px 1fr calc(150px + 20px);

    &--viewer {
      -ms-grid-columns: 100px 1fr calc(350px + 20px);
    }

    &--contact {
      -ms-grid-columns: 100px 1fr calc(350px + 20px);
    }

    .sidebar {
      @include colFlexboxAdvance('0 0 calc(150px + 20px)');
      -ms-grid-column: 3;

      &--viewer {
        @include colFlexboxAdvance('0 0 calc(350px + 20px)');
      }

      &--contact {
        @include colFlexboxAdvance('0 0 calc(350px + 20px)');
      }
    }
  }

  @media screen and (min-width: $screen-macpro) {
    -ms-grid-columns: 100px 1fr calc(350px + 20px);

    &--viewer {
      -ms-grid-columns: 100px 1fr calc(350px + 20px);
    }

    &--contact {
      -ms-grid-columns: 100px 1fr calc(350px + 20px);
    }

    .sidebar {
      @include colFlexboxAdvance('0 0 calc(350px + 20px)');
      -ms-grid-column: 3;

      &--viewer {
        @include colFlexboxAdvance('0 0 calc(350px + 20px)');
      }

      &--contact {
        @include colFlexboxAdvance('0 0 calc(350px + 20px)');
      }
    }
  }

  @media screen and (min-width: $screen-full) {
    -ms-grid-columns: 100px 1fr calc(500px + 20px);

    &--viewer {
      -ms-grid-columns: 100px 1fr calc(500px + 20px);
    }

    &--contact {
      -ms-grid-columns: 100px 1fr calc(350px + 20px);
    }

    .sidebar {
      @include colFlexboxAdvance('0 0 calc(500px + 20px)');
      -ms-grid-column: 3;

      &--viewer {
        @include colFlexboxAdvance('0 0 calc(500px + 20px)');
      }

      &--contact {
        @include colFlexboxAdvance('0 0 calc(350px + 20px)');
      }
    }
  }

  &--reduced {
    .sidebar {
      @include colFlexboxAdvance('0 0 0)');
      -ms-grid-column: 3
    }

    @media screen and (min-width: $screen-md) {
      -ms-grid-columns: 100px 1fr calc(350px + 20px);

      .sidebar {
        @include colFlexboxAdvance('0 0 0');
        -ms-grid-column: 3;

        &--viewer {
          @include colFlexboxAdvance('0 0 0');
        }

        &--contact {
          -ms-grid-columns: 100px 1fr calc(350px + 20px);
        }
      }
    }

    @media screen and (min-width: $screen-macpro) {
      -ms-grid-columns: 100px 1fr calc(350px + 20px);

      .sidebar {
        @include colFlexboxAdvance('0 0 0');
        -ms-grid-column: 3;

        &--viewer {
          @include colFlexboxAdvance('0 0 0');
        }

        &--contact {
          @include colFlexboxAdvance('0 0 calc(350px + 20px)');
        }
      }
    }

    @media screen and (min-width: $screen-full) {
      -ms-grid-columns: 100px 1fr calc(500px + 20px);

      .sidebar {
        @include colFlexboxAdvance('0 0 0');
        -ms-grid-column: 3;

        &--viewer {
          @include colFlexboxAdvance('0 0 0');
        }

        &--contact {
          -ms-grid-columns: 100px 1fr calc(350px + 20px);
        }
      }
    }
  }
}



@supports ((display: -ms-grid) or (display: grid)) {
  .all-site-wrap {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr calc(200px + 20px) 0;
    grid-template-columns: 1fr calc(200px + 20px) 0;

    &--viewer {
      -ms-grid-columns: 1fr calc(300px + 20px) 0;
      grid-template-columns: 1fr calc(300px + 20px) 0;
    }

    &--contact {
      -ms-grid-columns: 1fr calc(300px + 20px) 0;
      grid-template-columns: 1fr calc(300px + 20px) 0;
    }


    @media screen and (min-width: $screen-md) {
      -ms-grid-columns: $size_menu_col 1fr calc(250px + 20px);
      grid-template-columns: $size_menu_col 1fr calc(250px + 20px);

      &--viewer {
        -ms-grid-columns: $size_menu_col 1fr calc(350px + 20px);
        grid-template-columns: $size_menu_col 1fr calc(350px + 20px);
      }

      &--contact {
        -ms-grid-columns: $size_menu_col 1fr calc(350px + 20px);
        grid-template-columns: $size_menu_col 1fr calc(350px + 20px);
      }
    }

    @media screen and (min-width: $screen-macpro) {
      -ms-grid-columns: $size_menu_col 1fr calc(350px + 20px);
      grid-template-columns: $size_menu_col 1fr calc(350px + 20px);

      &--viewer {
        -ms-grid-columns: $size_menu_col 1fr calc(350px + 20px);
        grid-template-columns: $size_menu_col 1fr calc(350px + 20px);
      }

      &--contact {
        -ms-grid-columns: $size_menu_col 1fr calc(350px + 20px);
        grid-template-columns: $size_menu_col 1fr calc(350px + 20px);
      }
    }

    @media screen and (min-width: $screen-full) {
      -ms-grid-columns: $size_menu_col 1fr calc(500px + 20px);
      grid-template-columns: $size_menu_col 1fr calc(500px + 20px);
    }

    &--reduced {
      -ms-grid-columns: 1fr 0 0;
      grid-template-columns: 1fr 0 0;


      @media screen and (min-width: $screen-md) {
        -ms-grid-columns: $size_menu_col 1fr 0;
        grid-template-columns: $size_menu_col 1fr 0;
      }



      //@media screen and (min-width: $screen-macpro) {
      //  -ms-grid-columns: $size_menu_col 1fr 0;
      //  grid-template-columns: $size_menu_col 1fr 0;
      //}
      //
      //@media screen and (min-width: $screen-full) {
      //  -ms-grid-columns: $size_menu_col 1fr calc(500px + 20px);
      //  grid-template-columns: $size_menu_col 1fr calc(500px + 20px);
      //}
    }
  }


}




@media (max-width: $screen-mx-md) {

  //.all-site-wrap {
  //  -ms-grid-columns: 1fr calc(300px + 20px);
  //  grid-template-columns: 1fr calc(300px + 20px);
  //}

}

//@media (max-width: 735px) {
@media (max-width: $screen-mx-sm) {

  .all-site-wrap--contact {
    display: block;
  }
}



//************/
//*  Colores de fondo  */
//************/


@media print {
  .all-site-wrap {
    display: none !important;
  }
}



