.sidebar {

  &__close {
    display: inline-block;
    margin: 10px 10px 10px;
    position: relative;
    left: 0px;

    text-align: center;
    color: #333;

    @media screen and (min-width: $screen-sm) {
      margin: 15px 15px 0;
      left: 10px;
    }

    span {
      display: block;
      font-size: 8px;
    }
  }

  &--right {

  }

  &--books {
    //background: url("../images/sidebar-books.png") repeat;
    background: url("../images/sidebar-viewer.png") repeat;
  }

  &--chapters {
    //background: url("../images/sidebar-chapters.png") repeat;
    background: url("../images/sidebar-viewer.png") repeat;
  }

  &--viewer {
    background: url("../images/sidebar-viewer.png") repeat;
  }

  &--videos {
    //background: url("../images/sidebar-videos.png") repeat;
    background: url("../images/sidebar-viewer.png") repeat;
  }

  &--images {
    //background: url("../images/sidebar-images.png") repeat;
    background: url("../images/sidebar-viewer.png") repeat;
  }

  &--links {
    //background: url("../images/sidebar-links.jpg") repeat;
    background: url("../images/sidebar-viewer.png") repeat;
  }

  &--documents {
    //background: url("../images/sidebar-documents.png") repeat;
    background: url("../images/sidebar-viewer.png") repeat;
  }

  &--notes {
    //background: url("../images/sidebar-notes.png") repeat;
    background: url("../images/sidebar-viewer.png") repeat;
  }

  &--images,
  &--videos,
  &--documents,
  &--chapters,
  &--links,
  &--notes {
    .page__filter {
      margin: 0 20px 20px;
      padding: 15px;
      border-bottom: 0;

      background: #fff;
    }

    .page__filter:first-child {
      margin-top: 322px;
    }

    .page__filter-option {
      display: block;
      margin: 0 0 15px;
    }
  }

  &--contact {
    margin: 0 20px 20px;
    padding: 20px;

    font-family: $family_heading;
    font-size: 15px;
    color: #3f3e44;

    background: url("../images/sidebar-viewer.png") repeat;

    @media screen and (min-width: $screen-sm) {
      padding: 130px 20px 0;
    }

    @media screen and (min-width: $screen-md) {
      padding: 160px 40px 40px;
    }


    .contact-options {
      margin: 0 0 40px 0;
    }
    .contact-option {
      margin: 0 0 15px 0;
      padding:0 0 0 40px;
      position: relative;

      i {
        position: absolute;
        left: 0;
        font-size: 24px;
      }
      .fa-mobile {
        font-size: 30px;
        left: 5px;
        top: -2px;
      }
      .fa-envelope-o {
        font-size: 22px;
      }
    }

    .contact-social {

      text-align: center;

      .social-option {
        display: inline-block;
        margin: 20px;

        font-size: 40px;

        a {
          color: #3f3e44;
        }
        a:hover {
          color: #57565C;
        }
      }

    }


  }


  @media (max-width: $screen-mx-sm) {
    margin: 0 auto;
  }

  @media (max-width: $screen-mx-md) {
    @include colFlexboxAdvance('0 0 calc(300px + 2rem)');
    -ms-grid-column: 2;
  }

}