//***********/
//*  Fonts  */
//***********/

//
// Icon font
////////////////////////////////

@font-face {
  font-family: 'icon-font';
  src: url('../fonts/icon-font.eot?30371238');
  src: url('../fonts/icon-font.eot?30371238#iefix') format('embedded-opentype'),
  url('../fonts/icon-font.woff2?30371238') format('woff2'),
  url('../fonts/icon-font.woff?30371238') format('woff'),
  url('../fonts/icon-font.ttf?30371238') format('truetype'),
  url('../fonts/icon-font.svg?30371238#icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icon-font";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  // opacity: .8;

  // For safety - reset parent styles, that can break glyph codes
  font-variant: normal;
  text-transform: none;

  // fix buttons height, for twitter bootstrap
  line-height: 1em;

  // Animation center compensation - margins should be symmetric
  // remove if not needed
  margin-left: .2em;

  // you can be more comfortable with increased icons size
  // font-size: 120%;

  // Font smoothing. That was taken from TWBS
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Uncomment for 3D effect
  // text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}