.menu {
  position: relative;

  &__list-options{
    width: 100%;
    text-align: center;
  }

  &__option{

    text-align: left;
    border-bottom: 1px solid #3f3e44;

    @media (min-width: $screen-md) {
      text-align: center;
      border-bottom: 0;
    }

    > a {
      position: relative;
      display: inline-block;
      padding: 8px 20px;

      font-family: $family_heading;
      font-size: 13px;

      font-weight: 500;
      letter-spacing: 0.4px;
      color: $txt_color_menu;

      @media (min-width: $screen-md) {
        font-family: $family_menu;
        padding: 8px 5px;
        font-size: 7px;
        text-transform: uppercase;
      }
    }

    &--logo{
      display: none;

      @media (min-width: $screen-md) {
        display: block;
      }
    }

    &--chapters {
      //display: none;

      > a {
        font-size: 11px;
      }

      @media (min-width: $screen-md) {
        display: block;
        margin: 26px 0 12px;

        border-bottom: 1px solid #3f3e44;

        > a {
          font-size: 13px;
        }
      }


    }

    &--chapter {
      //display: none;
      padding-left: 20px;
      background-color: #3f3e44;

      > a {
        font-size: 11px;
      }

      @media (min-width: $screen-md) {
        //display: block;
        padding-left: 0;
        background-color: transparent;

        > a {
          font-size: 11px;
          color: #999;
        }

      }

      &.currentChapter {
        > a {
          color: #fff;
        }
      }
    }
  }

  &__icon{
    display: none;
    margin: 0 auto 3px;

    @media (min-width: $screen-md) {
      display: block;

      width: 40px;
      height: 40px;
      pointer-events: none;
    }

    fill: $color_svgs_menu;

    &--shop,
    &--home,
    &--video,
    &--chapter,
    &--book {
      fill: none;
      stroke: white;
      stroke-width: 2;
    }

  }

  &--main {

    width: 100%;

    &.open {
      display: block;
      position: fixed;
      top: $heightMenuMobile;
    }

    @media (min-width: $screen-md) {
      @include flexbox();
      @include flexDirection('column');
      @include flexNoWrap();

      &.open {
        @include flexbox();
        @include flexDirection('column');
        @include flexNoWrap();
        position: relative;
        top: 0;
      }
    }






  }


  &--secondary {
    padding: 0;
    background-color: #57565C;

    @media screen and (min-width: $screen-sm) {
      padding: 0 20px;
    }

    .menu__list-options {
      width: 100%;

      padding: 0;
      @include flexbox();
      @include flexNoWrap();
      @include flexDirection('row');
      @include alignItems();
    }

    .menu__option {
      margin: 0 0px 0 0;

      text-align: center;
      border-bottom: 0;

      @media screen and (min-width: $screen-sm) {
        margin: 0 20px 0 0;
      }
      @media screen and (min-width: $screen-md) {
        margin: 0 40px 0 0;
      }

      a {
        padding: 8px 7px;
        font-size: 11px;

        @media screen and (min-width: 425px) {
          padding: 8px 15px;
        }
        @media screen and (min-width: $screen-sm) {
          padding: 8px 20px;

          font-size: 9px;
        }
      }
    }

    .menu__icon {
      display: block;
    }


  }

  &--viewer {
    padding: 0 10px;
    background-color: $bkg_menu_viewer;

    .menu__list-options {
      width: 100%;

      padding: 0;
      @include flexbox();
      @include flexNoWrap();
      @include flexDirection('row');
      @include alignItems();
    }


    .menu__option {
      margin: 0 0px 0 0;

      text-align: center;
      border-bottom: 0;

      a {
        padding: 8px 5px;
        font-size: 7px;
        color: $txt_color_menu_viewer;

        span {
          display: none;
        }
      }


      @media screen and (min-width: $screen-sm) {
        margin: 0 10px 0 0;

        a {
          span {
            display: block;
          }
        }
      }
      @media screen and (min-width: $screen-md) {
        margin: 0 10px 0 0;
      }
    }

    .menu__option--separate-left {
      margin-left: 5px;

      @media (min-width: 375px) {
        margin-left: 12px;
      }

      @media (min-width: $screen-sm) {
        margin-left: 15px;
      }
    }

    .menu__icon {
      position: relative;
      top: 6px;
      display: block;
      width: 14px;
      height: 14px;


      fill: $color_svgs_viewer;

      @media screen and (min-width: $screen-sm) {
        top: 0;
        width: 20px;
        height: 20px;
        margin-bottom: 3px;
      }
    }

    .menu-nav__input--pages {
      color: $txt_color_menu_viewer;
    }

    .menu-nav__group-input {
      color: $txt_color_menu_viewer;
      text-align: center;
    }

    .menu__input--pages {
      display: block;
      width: 30px;
      height: 15px;

      text-align: center;
      color: $txt_color_menu;

      @media screen and (min-width: $screen-sm) {
        width: 40px;
        height: 21px;
        margin-bottom: 2px;
      }
    }

    .menu__group-input {
      position: relative;
      display: inline-block;
      padding: 8px 5px;

      font-family: $family_menu;
      font-weight: 500;
      font-size: 7px;
      letter-spacing: 0.4px;
      color: $txt_color_menu_viewer;

      text-transform: uppercase;

      @media screen and (min-width: $screen-md) {
        font-size: 9px;
      }
    }
  }


}