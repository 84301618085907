.grid-1-8 {
  width: 12.5%
}

.grid-1-6 {
  width: 16.66%
}

.grid-1-5 {
  width: 20%
}

.grid-1-4 {
  width: 25%
}

.grid-1-3 {
  width: 33.33%
}

.grid-1-2 {
  width: 50%
}

.grid-1-1 {
  width: 100%
}

.grid-2-3 {
  width: 66.66%
}

.grid-3-4 {
  width: 75%
}

.grid-4-5 {
  width: 80%
}

.grid-5-6 {
  width: 83.33%
}

.grid-7-8 {
  width: 87.5%
}

.grid [class*='grid-'] {
  float: left;
  position: relative;
  margin: 0 0 10px 0;
  padding-right: 10px
}

.grid [class*='grid-']:last-child {
  padding-right: 0
}

@media (max-width: 1085px) {
  .grid [class*='grid-'] {
    padding-right: 5px;
    margin-bottom: 10px
  }
}

@media (max-width: 735px) {
  .grid [class*='grid-'] {
    float: none;
    width: 100%;
    padding: 0 !important
  }
}

.flex-grid {
  @include flexbox();
}

.flex-grid > * {
  @include colFlexbox(1);
}

@media (max-width: $screen-mx-md) {
  .flex-grid {
    display: block
  }
}

.flex-center {
  @include flexbox();
  @include alignItems();
  @include justifyContent();
}